import { Component } from '@angular/core';

import { FormArray, FormBuilder, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { MessageBoardService } from '../../services/message-board.service';
import { LoadingService, UtilitiesService } from '@athlete-x/shared';
import { map } from 'rxjs';
import { FilterHelpersService } from '@athlete-x/features/directory/services/filters-helper.service';
import { TeamStore } from '@athlete-x/stores';
import { TeamType } from '@athlete-x/definitions';

@Component({
    selector: 'atx-post-message-modal',
    templateUrl: './post-message-modal.component.html',
    styleUrls: ['./post-message-modal.component.scss'],
})
export class PostMessageModalComponent {
    public form = this.formBuilder.group({
        message: ['', Validators.required],
        target_group: [['Alumni', 'Player', 'Staff', 'Parent', 'Friend'], Validators.required],
    });

    public attachments: Array<{
        filename: string;
        file: File;
    }> = [];

    public usersTypes$ = this._filterHelper.usersTypes$
        .pipe(
            map((userTypes) => {
                this.form.patchValue({
                    target_group: userTypes.map(e => e.value)
                });
                return userTypes;
            })
        );

    public isNotTeamGroup$ = this._teamStore.team$
        .pipe(
            map((team) => team.teamType !== TeamType.GROUP)
        );

    constructor(
        private formBuilder: FormBuilder,
        private _modalCtrl: ModalController,
        private _filterHelper: FilterHelpersService,
        private _teamStore: TeamStore
    ) {
    }

    public cancel() {
        return this._modalCtrl
            .dismiss(null, 'cancel');
    }

    public confirm() {
        return this._modalCtrl
            .dismiss({
                post: this.form.value,
                attachments: this.attachments
            }, 'confirm');
    }

    public addFile(event: any) {
        const {target} = event;
        for (const file of target.files) {
            if (!file) {
                return;

            } else if (file.size > 1048576) {
                // return await this._utlilities.presentAlert({
                //     header: 'Alert',
                //     message: 'File is too big (max 1MB)!',
                //     buttons: ['Ok']
                // });
                // return this._utlilities.presentToast('File is too big!');
            }

            const filename = file.name;

            const fileData = {
                filename,
                file
            };

            this.attachments.push(fileData);
        }
    }
}
